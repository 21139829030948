import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { useAuth } from '../../providers/AuthProvider';

export const Layout = (props: any) => {
    const { children } = props;
    const { authUser, loadingAuthState } = useAuth();

    return (
        <Flex as="main" direction="column" w="100%" align="top" maxH="100vh">
            {!loadingAuthState && (
                <Box>
                    {authUser && <Navbar />}
                    <Flex
                        direction="column"
                        align="left"
                        w={{ base: '95%', sm: '80%' }}
                        pt={3}
                        px={5}
                        flex={1}
                        margin="auto"
                        minH={{ base: '80vh', sm: 'calc(100vh - 120px)' }}
                    >
                        {children}
                    </Flex>
                    <Footer />
                </Box>
            )}
        </Flex>
    );
};
