import React from 'react';

import { Flex, Text, Box, Link, Button, useClipboard } from '@chakra-ui/react';
import { ShowStatus } from '../shows';
import { getShowURL } from '../../utils/common';
import { FaCopy } from 'react-icons/fa';

export const ShowCard = ({ show }: any) => {
    const showUrl = show ? getShowURL('default', show.slug) : 'null';
    const { hasCopied, onCopy } = useClipboard(showUrl);

    if (!show) return <></>;

    return (
        <Flex direction="column">
            <Flex direction="column" mb={4}>
                <Text fontWeight="bold">Status</Text>
                <Box>{show.status && <ShowStatus status={show.status} />}</Box>
            </Flex>

            <Flex direction="column" mb={4}>
                <Text fontWeight="bold">URL</Text>
                {show.slug && (
                    <Box justify="center">
                        <Link
                            href={getShowURL('default', show.slug)}
                            isExternal
                        >
                            {getShowURL('default', show.slug)}
                        </Link>

                        <Button
                            variant="solid"
                            width="auto"
                            ml={2}
                            size="sm"
                            onClick={onCopy}
                        >
                            <FaCopy />{' '}
                            {hasCopied ? (
                                <Text as="span" ml={2}>
                                    Copied
                                </Text>
                            ) : (
                                ''
                            )}
                        </Button>
                    </Box>
                )}
            </Flex>

            <Flex direction="column" mb={4}>
                <Text fontWeight="bold">Slug</Text>
                <Box>{show.slug && <Text>{show.slug}</Text>}</Box>
            </Flex>

            <Flex direction="column" mb={4}>
                <Text fontWeight="bold">Display in Home Page</Text>
                <Box>{show.featured && <Text>Yes</Text>}</Box>
            </Flex>

            <Flex direction="column" mb={4}>
                <Text fontWeight="bold">Event</Text>
                <Box>{show.event && JSON.stringify(show.event)}</Box>
            </Flex>

            <Flex direction="column" mb={4}>
                <Text fontWeight="bold">Sponsor</Text>
                <Box>{show.sponsor && JSON.stringify(show.sponsor)}</Box>
            </Flex>

            <Flex direction="column" mb={4}>
                <Text fontWeight="bold">Categories</Text>
                <Box>{show.categories && JSON.stringify(show.categories)}</Box>
            </Flex>

            <Flex direction="column" mb={4}>
                <Text fontWeight="bold">Player</Text>
                <Box>{show.player && JSON.stringify(show.player)}</Box>
            </Flex>
        </Flex>
    );
};
