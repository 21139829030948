import React, { useState } from 'react';

import { useQuery } from 'react-query';
import {
    Box,
    Button,
    Flex,
    Spacer,
    Stack,
    Text,
    Input,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    useToast,
    Select,
} from '@chakra-ui/react';
import { PageTitle } from '../layouts';
import { useParams } from 'react-router-dom';
import { getShowById } from '../../libs/show-api';
import { CreateTicketModal } from '../tickets/createTicketModal';
import {
    clearDevicesByAccessCode,
    findAll,
    findAllCsv,
    findByAccessCode,
    updateStatusByAccessCode,
} from '../../libs/ticket-api';
import { TICKET } from '../../utils/constants';
import { downloadAsCsv } from '../../utils/csv';
import { formatDate } from '../../utils/formatter';
import { useForm } from 'react-hook-form';

export const ShowTicketsPage = () => {
    let { showId } = useParams();
    const [tickets, setTickets]: any[] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ticketFilters, setTicketFilters] = useState({});
    const toast = useToast();

    const { data: show } = useQuery(['shows', showId], () =>
        getShowById('' + showId)
    );

    const handleDownloadAsCsv = () => {
        setIsLoading(true);
        if (showId) {
            findAllCsv(showId, {})
                .then((data) => {
                    downloadAsCsv(`${showId}-tickets`, data);
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.error(err);
                });
        }
    };

    const handleSearch = (query: any) => {
        setIsLoading(true);
        if (showId) {
            setTicketFilters(query);
            if (
                query.accessCode.length !== 0 &&
                query.description.length === 0 &&
                query.categoryCode === ''
            ) {
                findByAccessCode(showId, query.accessCode)
                    .then((data) => {
                        setTickets(data ? [data] : []);
                        setIsLoading(false);
                    })
                    .catch((err: any) => {
                        console.error(err);
                        setIsLoading(false);
                    });
            } else {
                findAll(showId, query, { limit: 10 })
                    .then((data) => {
                        setTickets(data);
                        setIsLoading(false);
                    })
                    .catch((err: any) => {
                        console.error(err);
                        setIsLoading(false);
                    });
            }
        }
    };

    const handleClearDevices = (index: any) => {
        setIsLoading(true);
        if (showId) {
            clearDevicesByAccessCode(showId, tickets[index].accessCode)
                .then(() => {
                    handleSearch(ticketFilters);
                    setIsLoading(false);
                    toast({
                        title: 'Ticket devices cleared',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.error(err);
                    toast({
                        title: 'Error clearing devices from ticket',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                });
        }
    };

    const handleChangeStatus = (index: any) => {
        setIsLoading(true);
        if (showId) {
            if (tickets[index].status === TICKET.STATUS.BLOCKED) {
                updateStatusByAccessCode(
                    showId,
                    tickets[index].accessCode,
                    TICKET.STATUS.ACTIVE
                )
                    .then(() => {
                        handleSearch(ticketFilters);
                        setIsLoading(false);
                        toast({
                            title: 'Ticket status changed',
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        console.error(err);
                        toast({
                            title: 'Error updating status',
                            status: 'error',
                            duration: 3000,
                            isClosable: true,
                        });
                    });
            } else {
                updateStatusByAccessCode(
                    showId,
                    tickets[index].accessCode,
                    TICKET.STATUS.BLOCKED
                )
                    .then(() => {
                        handleSearch(ticketFilters);
                        setIsLoading(false);
                        toast({
                            title: 'Ticket status changed',
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        console.error(err);
                        toast({
                            title: 'Error updating status',
                            status: 'error',
                            duration: 3000,
                            isClosable: true,
                        });
                    });
            }
        }
    };

    console.log(tickets);

    return (
        <Box>
            <PageTitle
                title="Tickets"
                breadcrumb={[
                    { url: '/shows', label: 'Shows' },
                    {
                        url: `/shows/${showId}`,
                        label: show?.event?.name || '-',
                    },
                ]}
            >
                <Box align="right">
                    <Button
                        disabled={!showId}
                        isLoading={isLoading}
                        onClick={handleDownloadAsCsv}
                    >
                        Download as CSV
                    </Button>
                    <CreateTicketModal
                        showId={showId}
                        categories={show?.categories}
                    />
                </Box>
            </PageTitle>
            <TicketFilter
                onSearch={handleSearch}
                isLoading={isLoading}
                categories={show?.categories}
            />

            {tickets.length > 0 ? (
                <Table variant="simple" mt="2">
                    <Thead>
                        <Tr>
                            <Th>Access Code</Th>
                            <Th>Category</Th>
                            <Th>Status</Th>
                            <Th>Devices</Th>
                            <Th>First Used On</Th>
                            <Th isNumeric>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tickets &&
                            tickets.map((ticket: any, index: any) => (
                                <Tr key={ticket.accessCode}>
                                    <Td>{ticket.accessCode}</Td>
                                    <Td>{ticket.categoryCode}</Td>
                                    <Td>{ticket.status}</Td>
                                    <Td>
                                        {ticket.devices
                                            ? ticket.devices.length || '-'
                                            : '-'}
                                    </Td>
                                    <Td>
                                        {ticket.firstUseOn
                                            ? formatDate(ticket.firstUseOn)
                                            : '-'}
                                    </Td>
                                    <Td isNumeric>
                                        <Button
                                            variant="solid"
                                            onClick={() =>
                                                handleClearDevices(index)
                                            }
                                            isLoading={isLoading}
                                        >
                                            Clear Devices
                                        </Button>

                                        <Button
                                            ml="2"
                                            variant="solid"
                                            onClick={() =>
                                                handleChangeStatus(index)
                                            }
                                            isLoading={isLoading}
                                        >
                                            {ticket.status === 'blocked' && (
                                                <Text>Activate</Text>
                                            )}
                                            {ticket.status !== 'blocked' && (
                                                <Text>Block</Text>
                                            )}
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            ) : (
                <Text align="center" mt={4}>
                    No tickets found
                </Text>
            )}
        </Box>
    );
};

const TicketFilter = (props: any) => {
    const { register, handleSubmit } = useForm();
    const { onSearch, isLoading, categories } = props;

    const handleSearch = (data: any) => {
        if (data.accessCode.length !== 0 || data.description.length !== 0) {
            onSearch(data);
        }

        if (
            data.accessCode.length === 0 &&
            data.description.length === 0 &&
            data.categoryCode !== ''
        ) {
            onSearch(data);
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSearch)}>
            <Stack
                direction={{ base: 'column', sm: 'row' }}
                bgColor="gray.100"
                py={2}
                px={4}
                borderRadius="6px"
                spacing={4}
            >
                <Flex direction="column">
                    <Text pb={2}>Search Code</Text>
                    <Input
                        type="text"
                        {...register('accessCode')}
                        bgColor="white"
                    />
                </Flex>
                <Flex direction="column">
                    <Text pb={2}>Description</Text>
                    <Input
                        type="text"
                        {...register('description')}
                        bgColor="white"
                    />
                </Flex>
                <Flex direction="column">
                    <Text pb={2}>Category</Text>
                    <Select bgColor="white" {...register('categoryCode')}>
                        <option value="">All</option>
                        {categories &&
                            Object.keys(categories).map((cat: any) => {
                                return (
                                    <option key={cat} value={cat}>
                                        {categories[cat].name}
                                    </option>
                                );
                            })}
                    </Select>
                </Flex>
                <Spacer />
                <Flex direction="row" align="center">
                    <Button
                        variant="solid"
                        colorScheme="blue"
                        isLoading={isLoading}
                        type="submit"
                    >
                        Search
                    </Button>
                </Flex>
            </Stack>
        </form>
    );
};
