import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from './components/layouts/Layout';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthProvider from './providers/AuthProvider';

import { LoginPage } from './components/pages/LoginPage';
import { ShowsPage } from './components/pages/ShowsPage';
import { ShowControlPanelPage } from './components/pages/ShowControlPanelPage';
import { CreateShowPage } from './components/pages/CreateShowPage';
import { ShowEditPage } from './components/pages/ShowEditPage';
import { ShowDetailsPage } from './components/pages/ShowDetailsPage';
import { ShowTicketsPage } from './components/pages/ShowTicketsPage';
import { SandboxPage } from './components/pages/SandboxPage';

import theme from './theme';
import { PublicRoute } from './components/routes/PublicRoute';
import { PrivateRoute } from './components/routes/PrivateRoute';

// Create a client
const queryClient = new QueryClient();

function App() {
    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <BrowserRouter>
                        <Layout>
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <PublicRoute>
                                            <LoginPage />
                                        </PublicRoute>
                                    }
                                />
                                <Route path="shows">
                                    <Route
                                        path="new"
                                        element={
                                            <PrivateRoute>
                                                <CreateShowPage />
                                            </PrivateRoute>
                                        }
                                    />

                                    <Route
                                        path=":showId/edit"
                                        element={
                                            <PrivateRoute>
                                                <ShowEditPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path=":showId/control-panel"
                                        element={
                                            <PrivateRoute>
                                                <ShowControlPanelPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path=":showId/tickets"
                                        element={
                                            <PrivateRoute>
                                                <ShowTicketsPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path=":showId"
                                        element={
                                            <PrivateRoute>
                                                <ShowDetailsPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        index
                                        element={
                                            <PrivateRoute>
                                                <ShowsPage />
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="sandbox"
                                    element={
                                        <PrivateRoute>
                                            <SandboxPage />
                                        </PrivateRoute>
                                    }
                                ></Route>
                                {/* <Route path="other" element={<OtherScreen />} /> */}
                            </Routes>
                        </Layout>
                    </BrowserRouter>
                </AuthProvider>
            </QueryClientProvider>
        </ChakraProvider>
    );
}

export default App;
