import React from 'react';

import { Box, Flex, Spacer, Text } from '@chakra-ui/layout';
import { useForm } from 'react-hook-form';
import { Select } from '@chakra-ui/select';
import { Button } from '@chakra-ui/button';
import { Textarea } from '@chakra-ui/react';

export const ShowControlPanelStatus = (props: any) => {
    const { register, handleSubmit } = useForm();
    const { mode, onSave } = props;

    const onSubmit = (data: any) => {
        onSave(data.mode);
    };

    return (
        <Box mb="2">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text>Show Status:</Text>
                <Flex direction="row">
                    <Box width="40%">
                        <Select defaultValue={mode} {...register('mode')}>
                            <option value="closed">Closed</option>
                            <option value="open">Open</option>
                            <option value="finalized">Finalized</option>
                        </Select>
                    </Box>
                    <Spacer />
                    <Box>
                        <Button type="submit">Save Changes</Button>
                    </Box>
                </Flex>
            </form>
        </Box>
    );
};

export const ShowControlPanelAccessMode = (props: any) => {
    const { register, handleSubmit } = useForm();
    const { accessMode, onSave } = props;

    const onSubmit = (data: any) => {
        onSave(data.accessMode);
    };

    return (
        <Box mb="2">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text>Access Mode:</Text>
                <Flex direction="row">
                    <Box width="40%">
                        <Select
                            defaultValue={accessMode}
                            {...register('accessMode')}
                        >
                            <option value="public">Public</option>
                            <option value="validate">Validate</option>
                        </Select>
                    </Box>
                    <Spacer />
                    <Box>
                        <Button type="submit">Save Changes</Button>
                    </Box>
                </Flex>
            </form>
        </Box>
    );
};

export const ShowControlPanelMessage = (props: any) => {
    const { register, handleSubmit } = useForm();
    const { message, onSave } = props;

    const onSubmit = (data: any) => {
        onSave({
            enabled: data.enabled === 'true',
            status: data.status,
            value: data.value,
        });
    };

    return (
        <Flex direction="column">
            Message Settings:
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt="1" ml="4">
                    <Text>Enabled:</Text>
                    <Flex direction="row">
                        <Box width="40%">
                            <Select
                                defaultValue={message.enabled}
                                {...register('enabled')}
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Select>
                        </Box>
                    </Flex>
                </Box>
                <Box mt="1" ml="4">
                    <Text>Status:</Text>
                    <Flex direction="row">
                        <Box width="40%">
                            <Select
                                defaultValue={message.status}
                                {...register('status')}
                            >
                                <option value="success">Success</option>
                                <option value="error">Error</option>
                                <option value="warning">Warning</option>
                                <option value="info">Info</option>
                            </Select>
                        </Box>
                    </Flex>
                </Box>
                <Box mt="1" ml="4">
                    <Text>Text:</Text>
                    <Flex direction="row">
                        <Box width="40%">
                            <Textarea
                                resize="none"
                                defaultValue={message.value}
                                {...register('value')}
                                size="sm"
                            />
                        </Box>
                        <Spacer />
                        <Box>
                            <Button type="submit">Save Changes</Button>
                        </Box>
                    </Flex>
                </Box>
            </form>
        </Flex>
    );
};
