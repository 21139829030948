import React, { useState } from 'react';

import { Box, Button, Flex, Heading, Input } from '@chakra-ui/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAuth } from '../../providers/AuthProvider';

type LoginInputs = {
    email: string;
    password: string;
};

export const LoginPage = () => {
    const { handleSubmit, register, formState } = useForm<LoginInputs>({
        mode: 'onChange',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null as any);

    const { signIn } = useAuth();

    const handleLogin: SubmitHandler<LoginInputs> = (data) => {
        setIsLoading(true);
        setMessage(null);
        signIn(data.email, data.password)
            .then((response: any) => {
                setIsLoading(false);
            })
            .catch((err: any) => {
                setIsLoading(false);
                if (err && err.msg) {
                    setMessage(err.msg);
                }
            });
    };

    return (
        <Flex
            height="calc(100vh - 84px)"
            alignItems="center"
            justifyContent="center"
        >
            <Flex
                w={{ base: '100%', sm: '30%' }}
                direction="column"
                p="12"
                rounded={6}
            >
                <form onSubmit={handleSubmit(handleLogin)}>
                    <Heading mb="6">MOONPLAY / Admin</Heading>
                    <Box mb="6">
                        <Input
                            {...register('email', { required: true })}
                            placeholder="mail@test.com"
                            variant="filled"
                            type="email"
                        />
                    </Box>
                    <Box mb="3">
                        <Input
                            {...register('password', { required: true })}
                            placeholder="********"
                            variant="filled"
                            type="password"
                        />
                    </Box>

                    {message && <Box my={3}>{message}</Box>}
                    <Button
                        disabled={!formState.isValid}
                        colorScheme="teal"
                        mb="6"
                        type="submit"
                        size="lg"
                        width="100%"
                        isLoading={isLoading}
                    >
                        Acceder
                    </Button>
                </form>
            </Flex>
        </Flex>
    );
};
