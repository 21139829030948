import React, { useEffect, useState, useContext } from 'react';
import { auth, db } from '../utils/firebase';

export const AuthContext = React.createContext<any>({} as any);

export default function AuthProvider({ children }: any) {
    const auth = useProvideAuth();

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};

function useProvideAuth() {
    const [authUser, setAuthUser] = useState(null as any);
    const [authenticated, setAuthenticated] = useState(false);
    const [loadingAuthState, setLoadingAuthState] = useState(true);
    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const signIn = (email: string, password: string) => {
        return new Promise((resolve, reject) => {
            auth.signInWithEmailAndPassword(
                auth.authentication,
                email,
                password
            )
                .then(async (response: any) => {
                    if (response && response.user) {
                        const authUser = await db.findOne(
                            `/users/${response.user.uid}`
                        );
                        if (authUser) setAuthUser(authUser);
                        return {
                            accessToken: response.user.accessToken,
                            authUser,
                        };
                    } else {
                        return {
                            msg: 'Authentication is OK but no user found',
                        };
                    }
                })
                .catch((err) => {
                    reject({ msg: 'Authentication error' });
                });
        });
    };

    /**
     * Log out user
     * @returns
     */
    const signOut = () => {
        return auth.signOut(auth.authentication).then(() => {
            setAuthUser(null);
        });
    };

    /*
    const signup = (email, password) => {
      return firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((response) => {
          setUser(response.user);
          return response.user;
        });
    };
   
    const sendPasswordResetEmail = (email) => {
      return firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          return true;
        });
    };
    const confirmPasswordReset = (code, password) => {
      return firebase
        .auth()
        .confirmPasswordReset(code, password)
        .then(() => {
          return true;
        });
    };
    */
    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(
            auth.authentication,
            async (user: any) => {
                // If user is authenticated
                if (user) {
                    setAuthenticated(true);
                    const authUser = await db.findOne(`/users/${user.uid}`);
                    if (authUser) setAuthUser(authUser);
                }

                // Otherwise, user is not authenticated
                else {
                    setAuthenticated(false);
                    setAuthUser(null);
                }
                setLoadingAuthState(false);
            }
        );
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    // Return the user object and auth methods
    return {
        authUser,
        authenticated,
        signIn,
        signOut,
        loadingAuthState,
    };
}
