import React from 'react';

import {
    Box,
    Checkbox,
    Flex,
    Input,
    InputGroup,
    InputLeftAddon,
    Text,
    useToast,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { PUBLIC_URL } from '../../utils/constants';
import {
    CategoriesElement,
    EventElement,
    PlayersElement,
    SponsorElement,
    TicketStatsElement,
} from '../shows/ShowEdit';
import { useQuery } from 'react-query';
import { getShowById, updateShowFeaturedById } from '../../libs/show-api';
import { PageTitle } from '../layouts';

export const ShowEditPage = () => {
    let { showId } = useParams();
    const toast = useToast();

    const { data: show } = useQuery(['shows', showId], () =>
        getShowById('' + showId)
    );

    const changeFeaturedShow = (value: any) => {
        if (showId) {
            updateShowFeaturedById(showId, value.target.checked)
                .then((res) => {
                    toast({
                        title: 'Show updated',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                })
                .catch((err) => {
                    console.error(err);
                    toast({
                        title: 'Error updating show',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                });
        }
    };

    return (
        <Box>
            {show && (
                <Box>
                    <PageTitle
                        title="Edit"
                        breadcrumb={[
                            { url: '/shows', label: 'Shows' },
                            {
                                url: `/shows/${showId}`,
                                label: show?.event?.name || '-',
                            },
                        ]}
                    ></PageTitle>

                    <Box pb="4">
                        <Text fontWeight="bold">Show</Text>
                        <Flex
                            direction="row"
                            borderTop="1px solid"
                            borderColor="gray.300"
                            py="2"
                        >
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <InputGroup>
                                    <InputLeftAddon
                                        display={{ base: 'none', sm: 'flex' }}
                                        children={`${PUBLIC_URL.DEFAULT}/`}
                                    />
                                    <Input
                                        type="text"
                                        value={showId}
                                        readOnly
                                    />
                                </InputGroup>
                            </Box>
                        </Flex>
                        <Flex>
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Checkbox
                                    defaultIsChecked={show.featured}
                                    onChange={changeFeaturedShow}
                                >
                                    Display in Home Page
                                </Checkbox>
                            </Box>
                        </Flex>
                    </Box>

                    <Box py="4">
                        <EventElement event={show.event} />
                    </Box>
                    <Box py="4">
                        <SponsorElement sponsor={show.sponsor} />
                    </Box>
                    <Box py="4">
                        <CategoriesElement categories={show.categories} />
                    </Box>
                    <Box py="4">
                        <PlayersElement players={show.player} />
                    </Box>
                    <Box py="4">
                        <TicketStatsElement ticketStats={show.ticketStats} />
                    </Box>
                </Box>
            )}
        </Box>
    );
};
