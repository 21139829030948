import { ExportToCsv } from 'export-to-csv';

export const downloadAsCsv = (fileName: string, data: any[]) => {

    const options = {
        filename: fileName,
        fieldSeparator: ',',
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: false,
        showTitle: false,
        title: fileName,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
}