import React from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getShowById } from '../../libs/show-api';
import { Box, Flex } from '@chakra-ui/react';
import { ShowControlPanel } from '../shows/ShowControlPanel';
import { PageTitle } from '../layouts';

export const ShowControlPanelPage = () => {
    let { showId } = useParams();

    const { data: show } = useQuery(['shows', showId], () =>
        getShowById('' + showId)
    );

    return (
        <Box>
            {!show && <></>}

            {show && (
                <Box>
                    <PageTitle
                        title="Control Panel"
                        breadcrumb={[
                            { url: '/shows', label: 'Shows' },
                            {
                                url: `/shows/${showId}`,
                                label: show?.event?.name || '-',
                            },
                        ]}
                    ></PageTitle>
                    <Flex
                        pb="4"
                        borderBottomStyle="solid"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.200"
                    ></Flex>
                    <Box
                        py="4"
                        borderBottomStyle="solid"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.200"
                    >
                        <ShowControlPanel env={'default'} showSlug={showId} />
                    </Box>
                    <Box py="4">
                        <ShowControlPanel env={'beta'} showSlug={showId} />
                    </Box>
                </Box>
            )}
        </Box>
    );
};
