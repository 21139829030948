export const CURRENT_VERSION = require("../../package.json").version;
export const TIMESTAMP_VERSION = process.env.REACT_APP_TIMESTAMP_VERSION || '-';
export const ENV = process.env.REACT_APP_ENV;

export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

export const APP_NAME = 'Moonplay Admin';

export const PUBLIC_URL = {
    DEFAULT: process.env.REACT_APP_PUBLIC_URL_DEFAULT,
    BETA: process.env.REACT_APP_PUBLIC_URL_BETA,
}

export const API = {

    SHOW: process.env.REACT_APP_API_SHOW,

    /* VALIDATE: process.env.REACT_APP_API_VALIDATE,
    VALIDATE_PUBLIC: process.env.REACT_APP_API_VALIDATE_PUBLIC, */

    LOGIN: process.env.REACT_APP_API_LOGIN,
    LOGOUT: process.env.REACT_APP_API_LOGOUT
}


export const SHOW = {
    STATUS: {
        PUBLISHED: 'published',
        DRAFT: 'draft',
        REMOVED: 'removed'
    }
}

export const TICKET = {
    STATUS: {
        ACTIVE: 'active',
        BLOCKED: 'blocked'
    }
}
