import { format } from "date-fns";


/**
 * Return date in specific format
 * @param d 
 * @param formatDate
 * @returns 
 */
export const formatDate = (d: Date, formatDate: string = 'yyyy-MM-dd HH:mm:ss') => {
    return format(d, formatDate);
}