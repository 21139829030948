import React, { useState } from 'react';

import {
    Box,
    Flex,
    Input,
    Button,
    Text,
    Stack,
    Divider,
} from '@chakra-ui/react';
import { PageTitle } from '../layouts';
import {
    findAll,
    findAllCsv,
    updateStatusByAccessCode,
    clearDevicesByAccessCode,
    generateCustomAccessCode,
    generateRandomAccessCodes,
    findByAccessCode,
} from '../../libs/ticket-api';
import { downloadAsCsv } from '../../utils/csv';

export const SandboxPage = () => {
    const [showSlug, setShowSlug] = useState('');

    return (
        <Box>
            <Stack direction="column" spacing={4}>
                <PageTitle title="Sandbox"></PageTitle>

                <Flex flex={1} direction="column">
                    <Text fontWeight="bold">Show Slug</Text>
                    <Input
                        value={showSlug}
                        onChange={(event: any) =>
                            setShowSlug(event.target.value)
                        }
                        placeholder="Show slug"
                    />
                </Flex>

                <Divider />

                <Tickets showSlug={showSlug} />

                <Ticket showSlug={showSlug} />

                <ChangeStatus showSlug={showSlug} />

                <ClearDevices showSlug={showSlug} />

                <GenerateAccessCodeCustom showSlug={showSlug} />

                <GenerateAccessCodeRandom showSlug={showSlug} />
            </Stack>
        </Box>
    );
};

const DisplayData = ({ data }: any) => {
    return (
        <Box bgColor="gray.100" fontSize="sm" mt={4} p={2} borderRadius={4}>
            {JSON.stringify(data)}
        </Box>
    );
};

const Tickets = (props: any) => {
    const { showSlug } = props;
    const [accessCode, setAccessCode] = useState('');
    const [categoryCode, setCategoryCode] = useState('default');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [tickets, setTickets] = useState([]);

    const handleFindTickets = () => {
        setIsLoading(true);
        findAll(
            showSlug,
            { accessCode, categoryCode, description },
            { limit: 10 }
        )
            .then((data) => {
                setIsLoading(false);
                setTickets(data);
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    const handleDownloadAsCsv = () => {
        setIsLoading(true);
        findAllCsv(showSlug, { categoryCode, description })
            .then((data) => {
                downloadAsCsv(`${showSlug}-tickets`, data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Tickets</Text>

            <Input
                value={accessCode}
                onChange={(event: any) => setAccessCode(event.target.value)}
                placeholder="Access code"
            />
            <Input
                value={categoryCode}
                onChange={(event: any) => setCategoryCode(event.target.value)}
                placeholder="Category code"
            />
            <Input
                value={description}
                onChange={(event: any) => setDescription(event.target.value)}
                placeholder="Description"
            />
            <Button
                mt={1}
                disabled={!showSlug}
                isLoading={isLoading}
                onClick={handleFindTickets}
            >
                Find Tickets
            </Button>
            <Button
                mt={1}
                disabled={!showSlug}
                isLoading={isLoading}
                onClick={handleDownloadAsCsv}
            >
                Download as CSV
            </Button>

            {tickets && tickets.length > 0 && <DisplayData data={tickets} />}
        </Flex>
    );
};

const Ticket = (props: any) => {
    const { showSlug } = props;
    const [accessCode, setAccessCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [ticket, setTicket] = useState(null);

    const handleFindTickets = () => {
        setIsLoading(true);
        findByAccessCode(showSlug, accessCode)
            .then((data) => {
                setIsLoading(false);
                setTicket(data);
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Ticket by access code</Text>

            <Input
                value={accessCode}
                onChange={(event: any) => setAccessCode(event.target.value)}
                placeholder="Access code"
            />
            <Button
                mt={1}
                disabled={!showSlug}
                isLoading={isLoading}
                onClick={handleFindTickets}
            >
                Find Ticket
            </Button>

            {ticket && <DisplayData data={[ticket]} />}
        </Flex>
    );
};

const ChangeStatus = (props: any) => {
    const { showSlug } = props;
    const [status, setStatus] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeStatus = () => {
        setIsLoading(true);
        updateStatusByAccessCode(showSlug, accessCode, status)
            .then(() => {
                setIsLoading(false);
                alert('Change status success');
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Change status</Text>

            <Input
                value={accessCode}
                onChange={(event: any) => setAccessCode(event.target.value)}
                placeholder="Access code"
            />
            <Input
                value={status}
                onChange={(event: any) => setStatus(event.target.value)}
                placeholder="Status"
            />
            <Button
                mt={1}
                disabled={!showSlug}
                isLoading={isLoading}
                onClick={handleChangeStatus}
            >
                Change status
            </Button>
        </Flex>
    );
};

const ClearDevices = (props: any) => {
    const { showSlug } = props;
    const [accessCode, setAccessCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleClearDevices = () => {
        setIsLoading(true);
        clearDevicesByAccessCode(showSlug, accessCode)
            .then(() => {
                setIsLoading(false);
                alert('Clear devices success');
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Clear devices</Text>
            <Input
                value={accessCode}
                onChange={(event: any) => setAccessCode(event.target.value)}
                placeholder="Access code"
            />
            <Button
                mt={1}
                disabled={!showSlug}
                isLoading={isLoading}
                onClick={handleClearDevices}
            >
                Clear devices
            </Button>
        </Flex>
    );
};

const GenerateAccessCodeCustom = (props: any) => {
    const { showSlug } = props;
    const [accessCode, setAccessCode] = useState('');
    const [categoryCode, setCategoryCode] = useState('default');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleGenerateAccessCode = () => {
        setIsLoading(true);
        generateCustomAccessCode(
            showSlug,
            categoryCode,
            accessCode,
            description || undefined
        )
            .then(() => {
                setIsLoading(false);
                alert('Access code created');
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Generate access code (custom)</Text>
            <Input
                value={accessCode}
                onChange={(event: any) => setAccessCode(event.target.value)}
                placeholder="Access code"
            />
            <Input
                value={categoryCode}
                onChange={(event: any) => setCategoryCode(event.target.value)}
                placeholder="Category Code"
            />
            <Input
                value={description}
                onChange={(event: any) => setDescription(event.target.value)}
                placeholder="Description - optional"
            />
            <Button
                mt={1}
                disabled={!showSlug}
                isLoading={isLoading}
                onClick={handleGenerateAccessCode}
            >
                Generate
            </Button>
        </Flex>
    );
};

const GenerateAccessCodeRandom = (props: any) => {
    const { showSlug } = props;
    const [count, setCount] = useState(10);
    const [categoryCode, setCategoryCode] = useState('default');
    const [description, setDescription] = useState('');
    const [tickets, setTickets] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleGenerateAccessCode = () => {
        setIsLoading(true);
        generateRandomAccessCodes(
            showSlug,
            categoryCode,
            +count,
            description || undefined
        )
            .then((data: any) => {
                if (data && data.tickets) setTickets(data.tickets);
                setIsLoading(false);
                alert('Access code created');
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Generate access code (random)</Text>
            <Input
                type="number"
                value={count}
                onChange={(event: any) => setCount(event.target.value)}
                placeholder="Count"
            />
            <Input
                value={categoryCode}
                onChange={(event: any) => setCategoryCode(event.target.value)}
                placeholder="Category Code"
            />
            <Input
                value={description}
                onChange={(event: any) => setDescription(event.target.value)}
                placeholder="Description - optional"
            />
            <Button
                mt={1}
                disabled={!showSlug}
                isLoading={isLoading}
                onClick={handleGenerateAccessCode}
            >
                Generate
            </Button>

            {tickets && tickets.length > 0 && <DisplayData data={tickets} />}
        </Flex>
    );
};
