import React from 'react';

import { Link } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { useNavigate } from 'react-router';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { PopoverTrigger } from '@chakra-ui/popover';

export const ChakraLink = (props: any) => {
    let navigate = useNavigate();
    const { url, label } = props;
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');

    function navigateTo() {
        navigate(url);
    }

    return (
        <PopoverTrigger>
            <Button
                as={Link}
                onClick={navigateTo}
                p={2}
                fontWeight={500}
                color={linkColor}
                _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                }}
                backgroundColor="transparent"
                _active={{
                    backgroundColor: 'transparent',
                }}
            >
                {label}
            </Button>
        </PopoverTrigger>
    );
};
