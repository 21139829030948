import React from 'react';

import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Link,
    Popover,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useAuth } from '../../providers/AuthProvider';
import { ChakraLink } from '../common/ChakraLink';

interface NavItem {
    label: string;
    subLabel?: string;
    href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
    {
        label: 'Shows',
        href: '/shows',
    },
    /* {
        label: 'Tickets',
        href: '#',
    },
    {
        label: 'Clients',
        href: '#',
    },
    {
        label: 'Settings',
        href: '#',
    }, */
];

export const Navbar = (props: any) => {
    const { isOpen, onToggle } = useDisclosure();

    const { signOut, authUser } = useAuth();

    const handleLogout = () => {
        signOut();
    };

    return (
        <Box>
            <Flex
                bg={'gray.200'}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}
            >
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}
                >
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? (
                                <CloseIcon w={3} h={3} />
                            ) : (
                                <HamburgerIcon w={5} h={5} />
                            )
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex
                    flex={{ base: 1 }}
                    justify={{ base: 'center', md: 'start' }}
                >
                    <Text
                        textAlign={useBreakpointValue({
                            base: 'center',
                            md: 'left',
                        })}
                        alignSelf="center"
                        fontFamily={'heading'}
                        color={useColorModeValue('gray.800', 'white')}
                    >
                        MOONPLAY / admin
                    </Text>

                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <DesktopNav />
                    </Flex>
                </Flex>

                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    align="baseline"
                    spacing={6}
                >
                    <Text fontWeight="bold">{authUser?.firstName}</Text>
                    <Button
                        onClick={handleLogout}
                        display={{ base: 'none', md: 'inline-flex' }}
                        fontWeight={600}
                        color={'white'}
                        bg={'red.400'}
                        href={'#'}
                        _hover={{
                            bg: 'red.300',
                        }}
                    >
                        Log Out
                    </Button>
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
};

const DesktopNav = () => {
    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <ChakraLink
                            url={navItem.href ?? '#'}
                            label={navItem.label}
                        />
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const MobileNav = () => {
    const auth = useAuth();

    const handleLogout = () => {
        auth.signOut();
    };

    return (
        <Box>
            <Stack
                bg={useColorModeValue('white', 'gray.800')}
                p={4}
                display={{ md: 'none' }}
            >
                {NAV_ITEMS.map((navItem) => (
                    <MobileNavItem key={navItem.label} {...navItem} />
                ))}
            </Stack>
            <Box
                p={4}
                borderTop={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
            >
                <Stack spacing={4}>
                    <Flex
                        py={2}
                        as={Link}
                        onClick={handleLogout}
                        justify={'space-between'}
                        align={'center'}
                        _hover={{
                            textDecoration: 'none',
                        }}
                    >
                        <Text
                            fontWeight={600}
                            color={useColorModeValue('gray.600', 'gray.200')}
                        >
                            Log out
                        </Text>
                    </Flex>
                </Stack>
            </Box>
        </Box>
    );
};

const MobileNavItem = ({ label, href }: NavItem) => {
    return (
        <Stack spacing={4}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}
                >
                    {label}
                </Text>
            </Flex>
        </Stack>
    );
};
