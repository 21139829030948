import React from 'react';

import { Flex, Textarea, Box, Button } from '@chakra-ui/react';
import { getEmbedShowURL } from '../../utils/common';
import { FaCopy } from 'react-icons/fa';
import { useClipboard } from '@chakra-ui/react';

export const ShowEmbedCode = (props: any) => {
    const { show } = props;

    const showUrl = show ? getEmbedShowURL('default', show.slug) : '';
    const htmlCode = `<iframe src="${showUrl}" width="100%" height="400px" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="MOONPLAY - ${show?.event?.name}"></iframe>`;

    const { hasCopied, onCopy } = useClipboard(htmlCode);

    if (!show) return <></>;

    return (
        <Flex direction="column" borderWidth="1px" borderRadius="lg" p={4}>
            <Box mb={2}>HTML Embed code:</Box>

            <Flex flex={1} direction="column">
                <Textarea
                    size="sm"
                    height="150px"
                    defaultValue={htmlCode}
                ></Textarea>

                <Box>
                    <Button
                        leftIcon={<FaCopy />}
                        variant="solid"
                        width="auto"
                        mt={2}
                        onClick={onCopy}
                    >
                        {hasCopied ? 'Copied' : 'Copy'}
                    </Button>
                </Box>
            </Flex>
        </Flex>
    );
};
