// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, doc, getDocs, getDoc, setDoc, updateDoc, deleteField, query, orderBy, where, limit, startAfter, increment, writeBatch } from "firebase/firestore"
import { getDatabase, ref, get, child, set } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, deleteObject } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCLJ7V0KGNwXlDjnoAv2r0VdolL9fy-oGM",
    authDomain: "beereal-streaming-prod.firebaseapp.com",
    databaseURL: "https://beereal-streaming-prod-default-rtdb.firebaseio.com",
    projectId: "beereal-streaming-prod",
    storageBucket: "beereal-streaming-prod.appspot.com",
    messagingSenderId: "538439173524",
    appId: "1:538439173524:web:f57ba77c2138cec98ebb81",
    measurementId: "G-R3DH4VCNXP"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const database = getDatabase(firebase);
const firebaseAuth = getAuth(firebase);
const firestore = getFirestore(firebase);
const firebaseStorage = getStorage();


/**
 * Log page_view in analytics
 */
export const logPageView = () => {
    logEvent(analytics, 'page_view');
}

/**
 * Ref object in real-time database
 * @param value 
 * @returns 
 */
export const syncValue = (value: string) => {
    return ref(database, value);
}


// BEGIN: Authentication *************************************************************

export const auth = {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    authentication: firebaseAuth
}

// END: Authentication *************************************************************


// BEGIN: Firestore *************************************************************

/**
 * Find list of docs
 * @param path 
 * @param queryData 
 * @param orderByData
 * @returns 
 */
const findAll = async (path: string, queryData: any = null, options: { order?: any, limit?: number, onlyIds?: boolean, startAfterDoc?: any } = { order: [], limit: 15, onlyIds: false, startAfterDoc: null }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {

            const docs: any[] = [];

            // Query
            const queries: any[] = [];
            if (queryData && queryData.length > 0)
                queryData.forEach((queryItem: any) => queries.push(where(queryItem[0], queryItem[1], queryItem[2])));

            const extraOptions = [];

            // Order by
            if (options.order)
                extraOptions.push(orderBy(options.order[0], options.order[1]));

            // Start after doc
            if (options.startAfterDoc)
                extraOptions.push(startAfter(options.startAfterDoc));

            const q = (extraOptions.length > 0)
                ? query(collection(firestore, path), ...queries, ...extraOptions, limit(options.limit || 15))
                : query(collection(firestore, path), ...queries, limit(options.limit || 15));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc: any) => {
                if (options.onlyIds)
                    docs.push({ id: doc.id, doc });
                else
                    docs.push({ id: doc.id, ...doc.data() })
            });
            resolve(docs);
        }

        catch (err) {
            console.error(err);
            reject({ msg: 'Error fetching docs' });
        }
    });
}


/**
 * Find all documents in a query without limit
 * @param path 
 * @param queryData 
 * @param options 
 * @returns 
 */
const findAllExtended = async (path: string, queryData: any = null, options: { order?: any, limit?: number, onlyIds?: boolean, startAfterDoc?: null } = { order: [], limit: 100000, onlyIds: false, startAfterDoc: null }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {

            let docs = await findAll(path, queryData, options);

            let lastDoc = docs && docs.length > 0 && docs[docs.length - 1];
            while (lastDoc) {

                // Get last doc to use as filter in new query
                options.startAfterDoc = lastDoc.doc;
                const newDocs = await findAll(path, queryData, options);
                if (newDocs && newDocs.length > 0) {
                    lastDoc = newDocs && newDocs.length > 0 && newDocs[newDocs.length - 1];
                    docs = docs.concat(newDocs);
                }
                else
                    lastDoc = null;
            }

            // Remove doc field
            docs = docs.map((doc: any) => { return { id: doc.id } });

            resolve(docs);
        }
        catch (err) {
            console.error(err);
            reject({ msg: 'Error fetching docs' });
        }
    });
}


/**
 * Find a doc
 * @param path 
 * @returns 
 */
const findOne = async (path: string): Promise<any> => {
    return new Promise((async (resolve, reject) => {
        try {
            const docRef = doc(firestore, path)
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot && docSnapshot.data)
                resolve(docSnapshot.data());
            else
                resolve(null);
        }
        catch (err) {
            console.error(err);
            reject({ msg: 'Error fetching docs' });
        }
    }))
}


/**
 * Create a new document
 * @param path 
 * @param id 
 * @returns 
 */
const create = async (path: string, id: string, data: any = {}): Promise<any> => {
    return new Promise((async (resolve, reject) => {
        try {

            const docRef: any = await setDoc(doc(firestore, path, id), data);
            if (docRef && docRef.id)
                resolve(true);
            else
                resolve(null);
        }
        catch (err) {
            console.error(err);
            reject({ msg: 'Error fetching docs' });
        }
    }))
}

/**
 * Update a document by id
 * @param path 
 * @param id
 * @param data 
 * @returns 
 */
const updateById = async (path: string, id: string, data: any): Promise<any> => {
    return new Promise((async (resolve, reject) => {
        try {
            const docRef: any = doc(firestore, path + '/' + id);
            if (docRef) {
                await setDoc(docRef, data, { merge: true });
                resolve(true);
            }
            else
                resolve(null);
        }
        catch (err) {
            console.error(err);
            reject({ msg: 'Error fetching docs' });
        }
    }))
}


/**
 * Get a doc reference
 * @param path 
 * @param id 
 * @returns 
 */
const getDocRef = async (path: string, id: string): Promise<any> => {
    const docRef: any = doc(firestore, path + '/' + id);
    return docRef;
}


/**
 * Create a new batch
 * @returns 
 */
const createBatch = () => {
    return writeBatch(firestore);
}


/**
 * Delete a field in a document by id
 * @param path 
 * @param id 
 * @param fieldName 
 */
const deleteFieldById = async (path: string, id: string, fieldName: string): Promise<any> => {
    return new Promise((async (resolve, reject) => {
        try {
            const docRef: any = doc(firestore, path + '/' + id);
            if (docRef) {
                const data: any = {};
                data[fieldName] = deleteField();
                await updateDoc(docRef, data);
                resolve(true);
            }
            else
                resolve(null);
        }
        catch (err) {
            console.error(err);
            reject({ msg: 'Error fetching docs' });
        }
    }))
}


export const db = {
    findOne,
    findAll,
    findAllExtended,
    create,
    updateById,
    deleteFieldById,
    increment,
    createBatch,
    getDocRef
}

// END: Firestore *************************************************************

// BEGIN: Realtime *************************************************************

/**
 * Read data once from Firebase Realtime
 * @param path 
 * @returns 
 */
const readOnce = async (path: string): Promise<any> => {
    return new Promise((async (resolve, reject) => {
        const dbRef = ref(database);
        get(child(dbRef, path)).then((snapshot: any) => {
            if (snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                resolve(null);
            }
        }).catch((err) => {
            console.error(err);
            reject({ msg: 'Error reading from real-time database' });
        });
    }));
}


/**
 * Write data once in Firebase Realtime
 * @param path 
 * @param data 
 * @returns 
 */
const write = async (path: string, data: any): Promise<any> => {
    return new Promise((async (resolve, reject) => {
        set(ref(database, path), data)
            .then(() => {
                resolve(true);
            })
            .catch((err: any) => {
                console.error(err);
                reject({ msg: 'Error writing in real-time database' });
            })
    }));
}

export const realtimeDb = {
    readOnce,
    write
}

// END: Realtime *************************************************************


// BEGIN: Storage *************************************************************

/**
 * Write JSON data in Storage
 * @param path 
 * @param jsonData 
 * @returns 
 */
const writeJSONData = async (path: string, jsonData: any): Promise<any> => {
    return new Promise((async (resolve, reject) => {

        // Create a Blob from the JSON-string
        const jsonString = JSON.stringify(jsonData);
        const blob = new Blob([jsonString], { type: "application/json" });

        const _storageRef = storageRef(firebaseStorage, path);
        uploadBytes(_storageRef, blob)
            .then((snapshot) => {
                resolve(snapshot);
            })
            .catch((err) => {
                console.error(err);
                reject({ msg: 'Error writing in storage' });
            })
    }))
}


/**
 * Delete a file from storage
 * @param path 
 * @returns 
 */
const deleteFile = async (path: string) => {
    return new Promise((async (resolve, reject) => {

        // Create a reference to the file to delete
        const fileRef = storageRef(firebaseStorage, path);

        // Delete the file
        deleteObject(fileRef)
            .then(() => {
                resolve(true);
            }).catch((err) => {
                // Uh-oh, an error occurred!
                console.error(err);
                reject({ msg: 'Error deleting from storage' });
            });

    }))

}

export const storage = {
    writeJSONData,
    deleteFile
}

// END: Storage *************************************************************


