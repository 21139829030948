
import { PUBLIC_URL } from './constants';


/**
 * Get Show URL based on env
 * @param environment 
 * @param showSlug 
 * @returns 
 */
export const getShowURL = (environment: string, showSlug: string) => {
    let showUrl = '';
    switch (environment) {
        case 'beta':
            showUrl = `${PUBLIC_URL.BETA}/${showSlug}`;
            break
        case 'default':
            showUrl = `${PUBLIC_URL.DEFAULT}/${showSlug}`;
            break;
    }
    return showUrl;
}


/**
 * Get embed Show URL based on env
 * @param environment 
 * @param showSlug 
 * @returns 
 */
export const getEmbedShowURL = (environment: string, showSlug: string) => {
    let showUrl = '';
    switch (environment) {
        case 'beta':
            showUrl = `${PUBLIC_URL.BETA}/embed/${showSlug}`;
            break
        case 'default':
            showUrl = `${PUBLIC_URL.DEFAULT}/embed/${showSlug}`;
            break;
    }
    return showUrl;
}


/**
 * Generate random code
 * @param length 
 * @returns 
 */
export const generateRandomCode = (length: number = 6) => {
    let result = '';
    const characters = 'ABCDEFGHJKLMNPQRTUVWXYZ2346789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

