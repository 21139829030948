import {
    Text,
    Flex,
    Spacer,
    Image,
    Link,
    useColorMode,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { CURRENT_VERSION, TIMESTAMP_VERSION } from '../../utils/constants';

export const Footer = () => {
    const { colorMode, toggleColorMode } = useColorMode();

    useEffect(() => {
        if (colorMode === 'dark') {
            toggleColorMode();
        }
    }, [colorMode, toggleColorMode]);

    return (
        <Flex
            direction={{ sm: 'row' }}
            align="center"
            w={{ base: '100%' }}
            h="60px"
            pt={{ base: 5, sm: 6 }}
            px={5}
            flex={1}
            margin="auto"
            pb={5}
        >
            <Flex direction="row">
                <Link href={'https://beerealit.com'} isExternal>
                    <Image height="30px" src={'/beereal_logo.jpeg'} />
                </Link>
            </Flex>
            <Spacer />
            <Flex direction="row">
                <Text fontSize="sm">
                    v{CURRENT_VERSION} {TIMESTAMP_VERSION}
                </Text>
            </Flex>
        </Flex>
    );
};
