import React, { useState } from 'react';

import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftAddon,
    Spacer,
    Text,
    useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PUBLIC_URL } from '../../utils/constants';
import { createShow } from '../../libs/show-api';
import { PageTitle } from '../layouts';

export const CreateShowPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, formState } = useForm({
        mode: 'onChange',
    });
    let navigate = useNavigate();
    const toast = useToast();

    const onSubmit = (data: any) => {
        setIsLoading(true);
        createShow(data.slug)
            .then((res) => {
                toast({
                    title: 'Show created',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setIsLoading(false);
                navigate(`/shows/${data.slug}/edit`);
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: 'Error creating show',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                setIsLoading(false);
            });
    };

    return (
        <Box>
            <PageTitle
                title="New"
                breadcrumb={[{ url: '/shows', label: 'Shows' }]}
            ></PageTitle>

            <Box pb="4">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Text fontWeight="bold">Show</Text>
                    <Flex
                        direction={{ base: 'column', sm: 'row' }}
                        borderTop="1px solid"
                        borderColor="gray.300"
                        py="2"
                    >
                        <Box width={{ base: '100%', sm: '40%' }}>
                            <InputGroup>
                                <InputLeftAddon
                                    display={{ base: 'none', sm: 'flex' }}
                                    children={`${PUBLIC_URL.DEFAULT}/`}
                                />
                                <Input
                                    {...register('slug', { required: true })}
                                    type="text"
                                    placeholder="show-url-example"
                                />
                            </InputGroup>
                            <Text mt={2} fontSize="sm" mb={3}>
                                Importante: Una vez definida la URL no es
                                posible cambiarla
                            </Text>
                        </Box>
                        <Spacer />
                        <Box width={{ base: '100%', sm: '40%' }} align="right">
                            <Button
                                type="submit"
                                disabled={!formState.isValid}
                                width={{ base: '100%', sm: 'auto' }}
                                isLoading={isLoading}
                            >
                                Create Show
                            </Button>
                        </Box>
                    </Flex>
                </form>
            </Box>
        </Box>
    );
};
