import { ENV, SHOW } from '../utils/constants';
import { db, realtimeDb, storage } from '../utils/firebase';
import { formatDate } from '../utils/formatter';

/**
 * Return list of Shows
 * @param query 
 * @returns 
 */
export const findShows = async (query: any) => {
    try {

        const queryData = [];
        if (query) {

            // Filter by status
            if (query.status) {

                switch (query.status) {
                    case 'all':
                        queryData.push(['status', 'in', [SHOW.STATUS.PUBLISHED, SHOW.STATUS.DRAFT]]);
                        break;
                    case 'published':
                        queryData.push(['status', '==', SHOW.STATUS.PUBLISHED]);
                        break;
                    case 'draft':
                        queryData.push(['status', '==', SHOW.STATUS.DRAFT]);
                        break;
                    case 'removed':
                        queryData.push(['status', '==', SHOW.STATUS.REMOVED]);
                        break;
                }
            }
            else {
                queryData.push(['status', 'in', [SHOW.STATUS.PUBLISHED, SHOW.STATUS.DRAFT]]);
            }

            // Filter by featured
            if (query.featured && query.featured === 'yes')
                queryData.push(['featured', '==', true]);

            // Filter by date
            if (query.date) {
                const date = new Date();
                date.setHours(date.getHours() - 12);
                switch (query.date) {
                    case 'next':
                        queryData.push(['event.date', '>=', formatDate(date)]);
                        break;
                    case 'past':
                        queryData.push(['event.date', '<', formatDate(date)]);
                        break;
                }
            }
        }

        const response = await db.findAll(`/${ENV}-shows`, queryData, { limit: 50, order: ['event.date', query && query.date && query.date === 'past' ? 'desc' : 'asc'] });
        return Promise.resolve(response);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Find a Show by id
 * @param showId 
 * @returns 
 */
export const getShowById = async (showId: string) => {
    try {
        const response = await db.findOne(`/${ENV}-shows/${showId}`);
        return Promise.resolve(response);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Create a new Show sending only the slug
 * @param showSlug 
 * @returns 
 */
export const createShow = async (showSlug: string) => {
    try {

        // Find show by slug
        const show = await getShowById(showSlug);

        // If show already exists with that slug, then return an error
        if (show) {
            return Promise.reject({ msg: 'Show already exists with that slug' });
        }

        // Otherwise - create it
        else {

            const response = await db.create(`/${ENV}-shows`, showSlug);

            // Create ticketStats and categories fields
            await db.updateById(`/${ENV}-shows`, showSlug, {
                slug: showSlug,
                event: { name: showSlug },
                ticketStats: { totalCount: 0, maxAllowedCount: 0, validatedCount: 0 },
                categories: { default: { name: 'Default' } },
                player: { default: [] },
                status: SHOW.STATUS.DRAFT,
                unpublishedChangesCount: 0
            });

            // Create control panel (default)
            await setControlPanel(showSlug, 'default', 'mode', 'closed');
            await setControlPanel(showSlug, 'default', 'accessMode', 'validate');
            await setControlPanel(showSlug, 'default', 'message', {
                enabled: false,
                status: 'success',
                value: '.',
            });

            // Create control panel (beta)
            await setControlPanel(showSlug, 'beta', 'mode', 'closed');
            await setControlPanel(showSlug, 'beta', 'accessMode', 'validate');
            await setControlPanel(showSlug, 'beta', 'message', {
                enabled: false,
                status: 'success',
                value: '.',
            });

            return Promise.resolve(response);
        }
    }
    catch (err: any) {
        return Promise.reject({ msg: err.msg || 'Error found' });
    }
}


/**
 * Update event's field in a Show by id
 * @param showSlug 
 * @param name 
 * @param description 
 * @param date 
 * @param imageCover 
 * @param venue 
 * @returns 
 */
export const updateShowEventById = async (showSlug: string, name: string, description: string, date: string, imageCover: string, venue: string) => {
    try {
        const response = await db.updateById(`/${ENV}-shows`, showSlug, {
            event: { name, description, date, image: { cover: imageCover }, venue },
            unpublishedChangesCount: db.increment(1)
        }
        );
        return Promise.resolve(response);
    }
    catch (err: any) {
        return Promise.reject({ msg: err.msg || 'Error found' });
    }
}


/**
 * Update sponsor's field in a Show by id
 * @param showSlug 
 * @param name 
 * @param description 
 * @param website 
 * @param imageLogo 
 * @returns 
 */
export const updateShowSponsorById = async (showSlug: string, name: string, description: string, website: any, imageLogo: string) => {
    try {
        const response = await db.updateById(`/${ENV}-shows`, showSlug, {
            sponsor: { name, description, website, image: { logo: imageLogo } },
            unpublishedChangesCount: db.increment(1)
        });
        return Promise.resolve(response);
    }
    catch (err: any) {
        return Promise.reject({ msg: err.msg || 'Error found' });
    }
}


/**
 * Update player
 * @param showSlug 
 * @param categories 
 * @returns 
 */
export const updateShowCategoriesById = async (showSlug: string, categories: any) => {
    try {
        const response = await db.updateById(`/${ENV}-shows`, showSlug, { categories, unpublishedChangesCount: db.increment(1) });
        return Promise.resolve(response);
    }
    catch (err: any) {
        return Promise.reject({ msg: err.msg || 'Error found' });
    }
}


/**
 * Update featured flag in a show
 * @param showSlug 
 * @param featured 
 * @returns 
 */
export const updateShowFeaturedById = async (showSlug: string, featured: boolean) => {
    try {
        if (featured) {
            const response = await db.updateById(`/${ENV}-shows`, showSlug, { featured });
            return Promise.resolve(response);
        }
        else {
            const response = await db.deleteFieldById(`/${ENV}-shows`, showSlug, 'featured');
            return Promise.resolve(response);
        }
    }
    catch (err: any) {
        return Promise.reject({ msg: err.msg || 'Error found' });
    }
}


/**
 * Update ticket stats
 * @param showSlug 
 * @param totalCount 
 * @param maxAllowedCount 
 * @returns 
 */
export const updateShowTicketStatsById = async (showSlug: string, maxAllowedCount: number) => {
    try {
        const response = await db.updateById(`/${ENV}-shows`, showSlug, { ticketStats: { maxAllowedCount } });
        return Promise.resolve(response);
    }
    catch (err: any) {
        return Promise.reject({ msg: err.msg || 'Error found' });
    }
}


/**
 * Update player
 * @param showSlug 
 * @param categories 
 * @returns 
 */
export const updateShowPlayerById = async (showSlug: string, playerData: any) => {
    try {
        const response = await db.updateById(`/${ENV}-shows`, showSlug, { player: { default: playerData } });
        return Promise.resolve(response);
    }
    catch (err: any) {
        return Promise.reject({ msg: err.msg || 'Error found' });
    }
}


/**
 * Delete a show
 * @param showSlug 
 * @returns 
 */
export const deleteShowById = async (showSlug: string) => {
    try {
        const response = await db.updateById(`/${ENV}-shows`, showSlug, { status: SHOW.STATUS.REMOVED });

        // Delete json from storage
        try {
            await storage.deleteFile(`/${ENV}-shows/${showSlug}/show.json`);
            return Promise.resolve(response);
        }
        catch (err) {
            return Promise.resolve(response);
        }

    }
    catch (err: any) {
        return Promise.reject({ msg: err.msg || 'Error found' });
    }
}

/**
 * Change status to 'draft'
 * @param showSlug 
 * @returns 
 */
export const draftShowById = async (showSlug: string) => {
    try {
        const response = await db.updateById(`/${ENV}-shows`, showSlug, { status: SHOW.STATUS.DRAFT });

        try {
            await storage.deleteFile(`/${ENV}-shows/${showSlug}/show.json`);
            return Promise.resolve(response);
        }
        catch (err) {
            return Promise.resolve(response);
        }
    }
    catch (err: any) {
        return Promise.reject({ msg: err.msg || 'Error found' });
    }
}


/**
 * Read control panel from a show
 * @param showSlug 
 * @param environment 
 * @returns 
 */
export const getControlPanel = async (showSlug: string, environment: string) => {
    try {
        const response = await realtimeDb.readOnce(`/${ENV}/shows/${showSlug}/${environment}`);
        return Promise.resolve(response);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Update control panel in a show
 * @param showSlug 
 * @param environment 
 * @param field 
 * @param data 
 * @returns 
 */
export const setControlPanel = async (showSlug: string, environment: string, field: string, data: any) => {
    try {
        const response = await realtimeDb.write(`/${ENV}/shows/${showSlug}/${environment}/${field}`, data);
        return Promise.resolve(response);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Publish latest show data to be displayed in access screen
 * @param showSlug 
 * @returns 
 */
export const publishShowById = async (showSlug: string) => {
    try {

        // Change publish shows
        await db.updateById(`/${ENV}-shows`, showSlug, { status: SHOW.STATUS.PUBLISHED, unpublishedChangesCount: 0 });

        // Write show in storage
        const show: any = await db.findOne(`${ENV}-shows/${showSlug}`)
        if (show) {
            delete show.player;
            delete show.ticketStats;
            delete show.unpublishedChangesCount;

            const response = await storage.writeJSONData(`/${ENV}-shows/${showSlug}/show.json`, show);
            return Promise.resolve(response);
        }
        else
            return Promise.resolve(true);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Publish shows in home page
 * @returns 
 */
export const publishShowsInHomePage = async () => {
    try {

        const query = [
            ['status', '==', SHOW.STATUS.PUBLISHED],
            ['featured', '==', true]
        ];

        const showsSnapshot: any = await db.findAll(`${ENV}-shows`, query, { limit: 50, order: ['event.date', 'asc'] });
        if (showsSnapshot) {
            let shows = showsSnapshot.map((show: any) => {
                if (show) {
                    delete show.player;
                    delete show.ticketStats;
                    delete show.unpublishedChangesCount;
                    return show;
                }
                else
                    return false;
            });

            const response = await storage.writeJSONData(`/${ENV}-shows/shows.json`, shows);
            return Promise.resolve(response);
        }
        else {
            return Promise.resolve();
        }

    }
    catch (err) {
        return Promise.reject(err);
    }
}