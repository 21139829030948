import React from 'react';

import { Flex, Progress, Box, Spacer } from '@chakra-ui/react';

export const ShowTicketStats = (props: any) => {
    const { ticketStats } = props;

    if (!ticketStats) return <></>;

    return (
        <Flex direction="column" borderWidth="1px" borderRadius="lg" p={4}>
            <Box mb={2}>Total: {ticketStats?.totalCount}</Box>

            <Flex flex={1} direction="column">
                <Progress
                    value={ticketStats?.validatedCount}
                    max={ticketStats?.maxAllowedCount}
                />
                <Flex direction="row" flex={1} pt={1} fontSize="sm">
                    Validados: {ticketStats?.validatedCount}
                    <Spacer />
                    Max: {ticketStats?.maxAllowedCount}
                </Flex>
            </Flex>
        </Flex>
    );
};
