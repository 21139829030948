import React, { useState } from 'react';

import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Stack,
    useDisclosure,
    Text,
    Flex,
    Spacer,
    Input,
    Select,
    useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {
    generateCustomAccessCode,
    generateRandomAccessCodes,
} from '../../libs/ticket-api';

export const CreateTicketModal = (props: any) => {
    const { showId, categories } = props;
    const [typeMethod, setTypeMethod] = useState('random');
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { register, handleSubmit } = useForm();
    const toast = useToast();

    const onSubmit = (data: any) => {
        setIsLoading(true);
        if (typeMethod === 'random') {
            generateRandomAccessCodes(
                showId,
                data.category,
                data.amount,
                data.description ? data.description : null
            )
                .then(() => {
                    onClose();
                    setIsLoading(false);
                    toast({
                        title: 'Tickets created',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                })
                .catch((err: any) => {
                    console.error(err);
                    setIsLoading(false);
                    toast({
                        title: 'Error creating tickets',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                });
        } else {
            generateCustomAccessCode(
                showId,
                data.category,
                data.accessCode,
                data.description ? data.description : null
            )
                .then(() => {
                    setIsLoading(false);
                    onClose();
                    toast({
                        title: 'Ticket created',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.error(err);
                    toast({
                        title: 'Error creating ticket',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                });
        }
    };

    return (
        <>
            <Button ml={2} onClick={onOpen}>
                + Tickets
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Tickets</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <Flex p="2">
                                <Text w="40%">Type</Text>
                                <Spacer />
                                <RadioGroup
                                    value={typeMethod}
                                    onChange={setTypeMethod}
                                >
                                    <Stack direction="row">
                                        <Radio value="random">Random</Radio>
                                        <Radio value="single">
                                            Personalized
                                        </Radio>
                                    </Stack>
                                </RadioGroup>
                            </Flex>

                            {typeMethod === 'random' && (
                                <Flex p="2">
                                    <Text w="40%" alignSelf="center">
                                        Amount
                                    </Text>
                                    <Spacer />
                                    <Input
                                        w="70%"
                                        type="number"
                                        max={5000}
                                        {...register('amount', {
                                            min: 1,
                                            max: 5000,
                                            maxLength: 5,
                                        })}
                                    />
                                </Flex>
                            )}

                            {typeMethod === 'single' && (
                                <Flex p="2">
                                    <Text w="40%" alignSelf="center">
                                        Code
                                    </Text>
                                    <Spacer />
                                    <Input
                                        w="70%"
                                        type="text"
                                        minLength={6}
                                        maxLength={10}
                                        {...register('accessCode', {
                                            minLength: 6,
                                            maxLength: 10,
                                        })}
                                    />
                                </Flex>
                            )}

                            <Flex p="2">
                                <Text w="40%" alignSelf="center">
                                    Category
                                </Text>
                                <Spacer />
                                <Select w="70%" {...register('category')}>
                                    {categories &&
                                        Object.keys(categories).map(
                                            (cat: any) => {
                                                return (
                                                    <option
                                                        key={cat}
                                                        value={cat}
                                                    >
                                                        {categories[cat].name}
                                                    </option>
                                                );
                                            }
                                        )}
                                </Select>
                            </Flex>

                            <Flex p="2">
                                <Text w="40%" alignSelf="center">
                                    Description
                                </Text>
                                <Spacer />
                                <Input
                                    w="70%"
                                    type="text"
                                    {...register('description')}
                                />
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                isLoading={isLoading}
                                type="submit"
                                colorScheme="blue"
                                mr={3}
                            >
                                Create
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};
