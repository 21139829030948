import React from 'react';

import {
    Flex,
    Text,
    Spacer,
    Breadcrumb,
    BreadcrumbItem,
    Box,
} from '@chakra-ui/react';
import { Link as LinkRouter } from 'react-router-dom';

export const PageTitle = (props: any) => {
    const { breadcrumb, title, children } = props;

    return (
        <Flex
            mb="6"
            mt="4"
            direction={{ base: 'column', sm: 'row' }}
            borderBottom="1px"
            borderColor="gray.300"
            pb="4"
        >
            <Breadcrumb
                fontSize={{ base: '1em', sm: '26px' }}
                fontWeight="bold"
            >
                {breadcrumb &&
                    breadcrumb.map((breadcrumbItem: any, idx: number) => (
                        <BreadcrumbItem key={idx} color="blue.400">
                            <LinkRouter to={breadcrumbItem.url}>
                                {breadcrumbItem.label}
                            </LinkRouter>
                        </BreadcrumbItem>
                    ))}

                <BreadcrumbItem isCurrentPage>
                    <Text>{title}</Text>
                </BreadcrumbItem>
            </Breadcrumb>

            {children && (
                <>
                    <Spacer />
                    <Box mt={{ base: 2, sm: 0 }}>{children}</Box>
                </>
            )}
        </Flex>
    );
};
