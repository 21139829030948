import { ENV, TICKET } from '../utils/constants';
import { db } from '../utils/firebase';
import { generateRandomCode } from '../utils/common';

/**
 * Return list of Tickets
 * @param showSlug
 * @param query 
 * @param options
 */
export const findAll = async (showSlug: string, query: any, options: { order?: any, limit?: number, onlyIds?: boolean }) => {
    try {

        const queryData = [];
        if (query) {

            // Filter by accessCode
            if (query.accessCode)
                queryData.push(['accessCode', '==', query.accessCode.toUpperCase()]);

            // Filter by categoryCode
            if (query.categoryCode)
                queryData.push(['categoryCode', '==', query.categoryCode]);

            // Filter by description
            if (query.description)
                queryData.push(['description', '==', query.description]);
        }

        let response = await db.findAll(`/${ENV}-tickets/${showSlug}/tickets`, queryData, options);

        if (response) {
            response = response.map((item: any) => {
                return {
                    ...item,
                    firstUseOn: item.firstUseOn ? item.firstUseOn.toDate() : undefined,
                    createdAt: item.createdAt ? item.createdAt.toDate() : undefined
                }
            })
        }

        return Promise.resolve(response);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Find all tickets prepared to CSV export
 * @param showSlug 
 * @param query 
 */
export const findAllCsv = async (showSlug: string, query: any) => {
    try {
        const queryData = [];
        if (query) {

            // Filter by accessCode
            if (query.accessCode)
                queryData.push(['accessCode', '==', query.accessCode.toUpperCase()]);

            // Filter by categoryCode
            if (query.categoryCode)
                queryData.push(['categoryCode', '==', query.categoryCode]);

            // Filter by description
            if (query.description)
                queryData.push(['description', '==', query.description]);
        }

        const response = await db.findAllExtended(`/${ENV}-tickets/${showSlug}/tickets`, queryData, { limit: 300, onlyIds: true });

        return Promise.resolve(response);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Find a ticket by access code
 * @param showSlug 
 * @param accessCode 
 */
export const findByAccessCode = async (showSlug: string, accessCode: string) => {
    try {
        let response = await db.findOne(`/${ENV}-tickets/${showSlug}/tickets/${accessCode.toUpperCase()}`);

        if (response) {
            response = {
                ...response,
                firstUseOn: response.firstUseOn ? response.firstUseOn.toDate() : undefined,
                createdAt: response.createdAt ? response.createdAt.toDate() : undefined
            };
        }

        return Promise.resolve(response);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Generate new access codes in a show
 * @param showSlug 
 * @param categoryCode 
 * @param accessCode 
 * @param description
 */
export const generateCustomAccessCode = async (showSlug: string, categoryCode: string, accessCode: string, description: string = null as any) => {
    try {

        const data: any = {
            accessCode: accessCode.toUpperCase(),
            categoryCode,
            status: TICKET.STATUS.ACTIVE,
            createdAt: new Date()
        }
        if (description)
            data.description = description;

        // Create ticket
        await db.create(`/${ENV}-tickets/${showSlug}/tickets`, accessCode.toUpperCase(), data);

        // Increment total count of tickets in show
        await db.updateById(`/${ENV}-shows`, showSlug, { ticketStats: { totalCount: db.increment(1) } });

        return Promise.resolve(true);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Generate random number of access codes
 * @param showSlug 
 * @param categoryCode 
 * @param count 
 * @param description
 * @returns 
 */
export const generateRandomAccessCodes = async (showSlug: string, categoryCode: string, count: number, description: string = null as any) => {
    try {

        // To save list of random access codes
        let accessCodes = [];

        let i = 0;

        // Security: allow to create only up-to 5000 documents at once
        if (count > 5000)
            count = 5000;

        while (i < count) {
            const randomAccessCode = generateRandomCode();

            // Check if access code has not been added
            if (accessCodes.indexOf(randomAccessCode) < 0) {
                accessCodes.push(randomAccessCode);
                i++;
            }
        }

        const tickets: any[] = [];
        accessCodes.forEach((accessCode: string) => {
            const data: any = { accessCode, categoryCode, status: TICKET.STATUS.ACTIVE, createdAt: new Date() };
            if (description)
                data.description = description;

            tickets.push(data);
        })

        // Items per chunk
        const perChunk = 250;

        const result = tickets.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk);

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [];
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
        }, []);

        for (let i = 0; i < result.length; i++) {

            // Create tickets as batch in firestore
            await createBatch(showSlug, result[i]);

            // Update total count of tickets in show
            await db.updateById(`/${ENV}-shows`, showSlug, { ticketStats: { totalCount: db.increment(result[i].length) } });
        }

        return Promise.resolve({ tickets });
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Update status field in access code
 * @param showSlug 
 * @param accessCode 
 * @param status 
 * @returns 
 */
export const updateStatusByAccessCode = async (showSlug: string, accessCode: string, status: string) => {
    try {
        await db.updateById(`/${ENV}-tickets/${showSlug}/tickets`, accessCode.toUpperCase(), { status });
        return Promise.resolve(true);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

/**
 * Clear the devices array in a ticket
 * @param showSlug 
 * @param accessCode 
 */
export const clearDevicesByAccessCode = async (showSlug: string, accessCode: string) => {
    try {
        await db.updateById(`/${ENV}-tickets/${showSlug}/tickets`, accessCode.toUpperCase(), { devices: [] });

        return Promise.resolve(true);
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Create new batch in firestore
 * @param showSlug 
 * @param tickets 
 */
async function createBatch(showSlug: string, tickets: any[]) {

    const batch = db.createBatch();

    // Iterate list of tickets and insert as batch in Firestore
    for (let i = 0; i < tickets.length; i++) {

        if (tickets[i]) {

            const { accessCode } = tickets[i];

            // Create a doc reference to the future doc
            const docRef: any = await db.getDocRef(`/${ENV}-tickets/${showSlug}/tickets`, accessCode);

            try {

                // Set doc in batch
                batch.set(docRef, tickets[i]);
            }
            catch (err) {
                console.error(err);
            }
        }
    }

    // Commit batch to firestore
    await batch.commit();
}