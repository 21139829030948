import React, { useEffect, useState } from 'react';

import { Stack, Flex, Text, Link } from '@chakra-ui/react';
import { getControlPanel, setControlPanel } from '../../libs/show-api';
import {
    ShowControlPanelStatus,
    ShowControlPanelMessage,
    ShowControlPanelAccessMode,
} from './ControlPanelElement';
import { useToast } from '@chakra-ui/toast';
import { getShowURL } from '../../utils/common';

export const ShowControlPanel = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [controlPanel, setControlPanel] = useState(null as any);
    const { env, showSlug } = props;

    const loadControlPanel = () => {
        setIsLoading(true);
        getControlPanel(showSlug, env).then((data) => {
            setControlPanel(data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        // TODO Duplicated code since React asks to include getControlPanel in
        // dependency array
        setIsLoading(true);
        getControlPanel(showSlug, env).then((data) => {
            setControlPanel(data);
            setIsLoading(false);
        });
    }, [env, showSlug]);

    return (
        <Flex direction="column">
            <Text fontWeight="bold" fontSize="lg">
                Control Panel - {capitalize(env)}
            </Text>

            <Link mt={2} href={getShowURL(env, showSlug)} isExternal>
                {getShowURL(env, showSlug)}
            </Link>

            {isLoading && <Text>Loading...</Text>}

            {controlPanel && (
                <ControlPanel
                    controlPanel={controlPanel}
                    env={env}
                    showSlug={showSlug}
                    loadControlPanel={loadControlPanel}
                />
            )}
        </Flex>
    );
};

function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const ControlPanel = (props: any) => {
    const { controlPanel, showSlug, env, loadControlPanel } = props;
    const toast = useToast();

    const handleSaveStatus = (mode: any) => {
        setControlPanel(showSlug, env, 'mode', mode)
            .then(() => {
                showSuccessToast();
                loadControlPanel();
            })
            .catch((err: any) => {
                showErrorToast();
                console.error(err);
            });
    };

    const handleSaveAccessMode = (accessMode: any) => {
        setControlPanel(showSlug, env, 'accessMode', accessMode)
            .then(() => {
                showSuccessToast();
                loadControlPanel();
            })
            .catch((err: any) => {
                showErrorToast();
                console.error(err);
            });
    };

    const handleSaveMessage = (message: any) => {
        const msg = {
            enabled: message.enabled,
            status: message.status,
            value: message.value,
        };
        setControlPanel(showSlug, env, 'message', msg)
            .then(() => {
                showSuccessToast();
                loadControlPanel();
            })
            .catch((err: any) => {
                showErrorToast();
                console.error(err);
            });
    };

    const showSuccessToast = () => {
        toast({
            title: 'Show saved',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    const showErrorToast = () => {
        toast({
            title: 'Error saving show',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Stack mt={10} spacing={8}>
            <ShowControlPanelStatus
                mode={controlPanel?.mode}
                onSave={handleSaveStatus}
            />
            <ShowControlPanelAccessMode
                accessMode={controlPanel?.accessMode}
                onSave={handleSaveAccessMode}
            />
            <ShowControlPanelMessage
                message={controlPanel?.message}
                onSave={handleSaveMessage}
            />
        </Stack>
    );
};
