import React from 'react';

import { Badge } from '@chakra-ui/react';
import { SHOW } from '../../utils/constants';

export const ShowStatus = ({ status }: any) => {
    if (!status) return <></>;
    let statusLabel = status;
    let statusColor = 'default';

    switch (status) {
        case SHOW.STATUS.PUBLISHED:
            statusLabel = 'Published';
            statusColor = 'green';
            break;
        case SHOW.STATUS.DRAFT:
            statusLabel = 'Draft';
            break;
        case SHOW.STATUS.REMOVED:
            statusLabel = 'Removed';
            statusColor = 'red';
            break;
    }

    return (
        <Badge
            fontSize="sm"
            px={2}
            py={1}
            borderRadius="6px"
            colorScheme={statusColor}
        >
            {statusLabel}
        </Badge>
    );
};
