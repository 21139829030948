import React, { useEffect, useState } from 'react';

import { Box, Flex, Spacer, Text, Code } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Input } from '@chakra-ui/input';
import { Textarea } from '@chakra-ui/textarea';
import { Button } from '@chakra-ui/button';
import { Select } from '@chakra-ui/select';
import {
    updateShowEventById,
    updateShowPlayerById,
    updateShowSponsorById,
    updateShowTicketStatsById,
} from '../../libs/show-api';
import { useParams } from 'react-router';
import { useToast } from '@chakra-ui/toast';

export const EventElement = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit } = useForm();
    const { event } = props;
    const toast = useToast();
    let { showId } = useParams();

    const onSubmit = (data: any) => {
        if (showId) {
            setIsLoading(true);
            updateShowEventById(
                showId,
                data.name,
                data.description,
                data.date,
                data.cover,
                data.venue
            )
                .then((res) => {
                    toast({
                        title: 'Show updated',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    toast({
                        title: 'Error updating show',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoading(false);
                });
        }
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Event</Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box borderTop="1px solid" borderColor="gray.300" py="2">
                    <Box mt="1">
                        <Text>Name:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Input
                                    type="text"
                                    defaultValue={event?.name ? event.name : ''}
                                    required={true}
                                    {...register('name')}
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt="1">
                        <Text>Description:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Textarea
                                    resize="none"
                                    defaultValue={
                                        event?.description
                                            ? event.description
                                            : ''
                                    }
                                    {...register('description')}
                                    size="sm"
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt="1">
                        <Text>Date:</Text>
                        <Flex direction="column">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Input
                                    defaultValue={event?.date ? event.date : ''}
                                    type="text"
                                    {...register('date')}
                                />
                            </Box>
                            <Text mt={2} fontSize="sm" mb={3}>
                                Formato de fecha:{' '}
                                <Code>yyyy-MM-dd hh:mm:ss</Code> (por ejemplo:{' '}
                                <Code>2021-11-28 15:00:00</Code>)
                            </Text>
                        </Flex>
                    </Box>
                    <Box mt="1">
                        <Text>Venue:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Input
                                    defaultValue={
                                        event?.venue ? event.venue : ''
                                    }
                                    type="text"
                                    {...register('venue')}
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt="1">
                        <Text>Cover Image URL:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Input
                                    defaultValue={
                                        event?.image?.cover
                                            ? event.image.cover
                                            : ''
                                    }
                                    type="url"
                                    {...register('cover')}
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Spacer />
                    <Box mt={4}>
                        <Button
                            width={{ base: '100%', sm: 'auto' }}
                            isLoading={isLoading}
                            type="submit"
                        >
                            Save Event Changes
                        </Button>
                    </Box>
                </Box>
            </form>
        </Flex>
    );
};

export const SponsorElement = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit } = useForm();
    const { sponsor } = props;
    const toast = useToast();
    let { showId } = useParams();

    const onSubmit = (data: any) => {
        if (showId) {
            setIsLoading(true);
            updateShowSponsorById(
                showId,
                data.name,
                data.description,
                { url: data.url, label: data.label },
                data.logo
            )
                .then((res) => {
                    toast({
                        title: 'Show updated',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    toast({
                        title: 'Error updating show',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoading(false);
                });
        }
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Sponsor</Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box borderTop="1px solid" borderColor="gray.300" py="2">
                    <Box mt="1">
                        <Text>Name:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Input
                                    type="text"
                                    required={true}
                                    defaultValue={
                                        sponsor?.name ? sponsor.name : ''
                                    }
                                    {...register('name')}
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt="1">
                        <Text>Description:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Textarea
                                    resize="none"
                                    defaultValue={
                                        sponsor?.description
                                            ? sponsor.description
                                            : ''
                                    }
                                    {...register('description')}
                                    size="sm"
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt="1">
                        <Text>Website:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Input
                                    type="text"
                                    {...register('label')}
                                    defaultValue={
                                        sponsor?.website?.label
                                            ? sponsor.website.label
                                            : ''
                                    }
                                    placeholder="Label"
                                    mb="1"
                                />
                                <Input
                                    type="text"
                                    {...register('url')}
                                    defaultValue={
                                        sponsor?.website?.url
                                            ? sponsor.website.url
                                            : ''
                                    }
                                    placeholder="Website URL"
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt="1">
                        <Text>Logo Image URL:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Input
                                    defaultValue={
                                        sponsor?.image?.logo
                                            ? sponsor.image.logo
                                            : ''
                                    }
                                    type="url"
                                    {...register('logo')}
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Spacer />
                    <Box mt={4}>
                        <Button
                            width={{ base: '100%', sm: 'auto' }}
                            isLoading={isLoading}
                            type="submit"
                        >
                            Save Sponsor Changes
                        </Button>
                    </Box>
                </Box>
            </form>
        </Flex>
    );
};

export const CategoriesElement = (props: any) => {
    const { categories } = props;

    let contentDisplay = null;
    if (!categories) contentDisplay = <Text>No existen categories</Text>;

    const { handleSubmit } = useForm();

    const onSubmit = (data: any) => {
        // Edit Show Categories
    };

    const categoryKeys = Object.keys(categories);
    contentDisplay = (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box borderTop="1px solid" borderColor="gray.300" py="2">
                {categoryKeys &&
                    categoryKeys.map((categoryKey: string) => (
                        <Text key={categoryKey}>
                            - {categories[categoryKey].name} (
                            {<Code>{categoryKey}</Code>})
                        </Text>
                    ))}
            </Box>
        </form>
    );

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Categories</Text>
            {contentDisplay}
        </Flex>
    );
};

export const PlayersElement = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { handleSubmit, register } = useForm();
    const [showPlayers, setShowPlayers] = useState([] as any);
    let { showId } = useParams();
    const toast = useToast();
    const { players } = props;

    useEffect(() => {
        const originalShowPlayers: any[] = [];
        if (players && players['default']) {
            players['default'].forEach((element: any) => {
                originalShowPlayers.push(element);
            });
        }
        setShowPlayers(originalShowPlayers);
    }, [players]);

    const addNewPlayer = () => {
        const name: string = 'Player ' + (showPlayers.length + 1).toString();
        setShowPlayers([...showPlayers, { label: name }]);
    };

    const onSubmit = (data: any) => {
        if (showId) {
            setIsLoading(true);
            let playersElement: any[] = [];
            showPlayers.forEach((element: any, index: any) => {
                playersElement.push({
                    label: data['label' + index],
                    type: data['type' + index],
                    value: data['value' + index],
                });
            });
            updateShowPlayerById(showId, playersElement)
                .then((res) => {
                    toast({
                        title: 'Show updated',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    toast({
                        title: 'Error updating show',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoading(false);
                });
        }
    };

    const onRemovePlayer = (index: any) => {
        const aux: any[] = [];
        showPlayers.forEach((element: any, i: any) => {
            if (i !== index) {
                aux.push(element);
            }
        });
        setShowPlayers(aux);
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Players</Text>
            <Box borderTop="1px solid" borderColor="gray.300" py="2">
                <Box mt="1">
                    <Button onClick={addNewPlayer}>+ Player</Button>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box width="100%">
                        {showPlayers &&
                            showPlayers.map((player: any, index: any) => {
                                return (
                                    <PlayerElement
                                        key={index}
                                        onRemovePlayer={onRemovePlayer}
                                        player={player}
                                        index={index}
                                        register={register}
                                    />
                                );
                            })}
                    </Box>
                    <Flex>
                        <Button
                            width={{ base: '100%', sm: 'auto' }}
                            type="submit"
                            variant="solid"
                            isLoading={isLoading}
                            mt={3}
                        >
                            Save Player Changes
                        </Button>
                    </Flex>
                </form>
            </Box>
        </Flex>
    );
};

const PlayerElement = (props: any) => {
    const { index, onRemovePlayer, register } = props;

    return (
        <Box>
            <Box py="2">
                <Text>Player {index + 1}</Text>
                <Box mt="1">
                    <Text>Label:</Text>
                    <Flex direction="row">
                        <Box width={{ base: '100%', sm: '40%' }}>
                            <Input
                                type="text"
                                placeholder={props?.player?.label}
                                defaultValue={
                                    props?.player?.label
                                        ? props.player.label
                                        : ''
                                }
                                required={true}
                                {...register('label' + index)}
                            />
                        </Box>
                        <Spacer />
                        <Box>
                            <Button
                                mr="2"
                                onClick={() => onRemovePlayer(index)}
                            >
                                Remove Player
                            </Button>
                        </Box>
                    </Flex>
                </Box>
                <Box mt="1">
                    <Text>Type:</Text>
                    <Flex direction="row">
                        <Box width={{ base: '100%', sm: '40%' }}>
                            <Select
                                defaultValue={
                                    props?.player?.type
                                        ? props.player.type
                                        : 'embed'
                                }
                                {...register('type' + index)}
                            >
                                <option value="embed">Embed</option>
                                <option value="hls">HLS</option>
                            </Select>
                        </Box>
                    </Flex>
                </Box>
                <Box mt="1">
                    <Text>URL:</Text>
                    <Flex direction="row">
                        <Box width={{ base: '100%', sm: '40%' }}>
                            <Input
                                type="text"
                                required={true}
                                defaultValue={
                                    props?.player?.value
                                        ? props.player.value
                                        : ''
                                }
                                {...register('value' + index)}
                            />
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </Box>
    );
};

export const TicketStatsElement = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit } = useForm();
    const { ticketStats } = props;
    const toast = useToast();
    let { showId } = useParams();

    const onSubmit = (data: any) => {
        if (showId) {
            setIsLoading(true);
            updateShowTicketStatsById(showId, +data.maxAllowed)
                .then((res) => {
                    toast({
                        title: 'Show updated',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    toast({
                        title: 'Error updating show',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoading(false);
                });
        }
    };

    return (
        <Flex direction="column">
            <Text fontWeight="bold">Ticket Stats</Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box borderTop="1px solid" borderColor="gray.300" py="2">
                    <Box mt="1">
                        <Text>Total:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                {ticketStats?.totalCount
                                    ? ticketStats.totalCount
                                    : 0}
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt="1">
                        <Text>Max Allowed:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                <Input
                                    type="number"
                                    defaultValue={ticketStats?.maxAllowedCount}
                                    required={true}
                                    {...register('maxAllowed')}
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt="1">
                        <Text>Validated:</Text>
                        <Flex direction="row">
                            <Box width={{ base: '100%', sm: '40%' }}>
                                {ticketStats?.validatedCount
                                    ? ticketStats.validatedCount
                                    : 0}
                            </Box>
                        </Flex>
                    </Box>

                    <Box mt={4}>
                        <Button
                            width={{ base: '100%', sm: 'auto' }}
                            isLoading={isLoading}
                            type="submit"
                        >
                            Save Ticket Stats Changes
                        </Button>
                    </Box>
                </Box>
            </form>
        </Flex>
    );
};
