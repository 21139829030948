import React, { useState } from 'react';

import {
    Button,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Box,
    Spacer,
    Flex,
    Stack,
    Text,
    Select,
    useBreakpointValue,
    useToast,
} from '@chakra-ui/react';
import { useTable, useSortBy } from 'react-table';
import { useQuery } from 'react-query';
import { findShows, publishShowsInHomePage } from '../../libs/show-api';
import { Link as LinkRouter, useSearchParams } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { PageTitle } from '../layouts';
import { ShowTicketStats, ShowStatus } from '../shows';
import { SHOW } from '../../utils/constants';

export const ShowsPage = () => {
    const [publishHomeIsLoading, setPublishHomeIsLoading] = useState(false);
    const isMobile = useBreakpointValue({ base: true, sm: false });
    const toast = useToast();

    let [queryParams, setQueryParams] = useSearchParams();
    const [query, setQuery] = useState({
        status: queryParams.get('status') || 'all',
        date: queryParams.get('date') || 'next',
        featured: queryParams.get('featured') || 'all',
    });

    const handleSearch = (_query: any) => {
        setQuery(_query);
        setQueryParams(_query);
    };

    const { isLoading, data: shows } = useQuery(
        ['shows', query.status, query.date, query.featured],
        () => findShows(query)
    );

    const handlePublish = () => {
        setPublishHomeIsLoading(true);
        publishShowsInHomePage()
            .then(() => {
                toast({
                    title: 'Shows published',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setPublishHomeIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: 'Error publishing shows',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                setPublishHomeIsLoading(false);
            });
    };

    return (
        <Stack direction="column" spacing={4}>
            <PageTitle title="Shows">
                <Stack direction="row" align="right" spacing={4}>
                    <Button
                        variant="solid"
                        onClick={handlePublish}
                        isLoading={publishHomeIsLoading}
                    >
                        Publish on Home Page
                    </Button>
                    <LinkRouter to={`/shows/new`}>
                        <Button variant="solid">+ Show</Button>
                    </LinkRouter>
                </Stack>
            </PageTitle>

            <ShowFilters onSearch={handleSearch} query={query} />
            {isLoading && <Text>Loading shows...</Text>}
            {!isLoading && !shows && <Text>No shows found</Text>}
            {!isLoading && shows && isMobile !== undefined && (
                <ShowsTable shows={shows} isMobile={isMobile} />
            )}
        </Stack>
    );
};

const ShowsTable = ({ shows, isMobile }: any) => {
    const columns: any = React.useMemo(
        () => [
            {
                id: 'status',
                Header: 'Status',
                accessor: 'status',
            },
            {
                id: 'event.info',
                Header: 'Event',
                accessor: 'event.info',
            },
            {
                id: 'sponsor',
                Header: 'Sponsor',
                accessor: 'sponsor.name',
                show: false,
            },
            {
                id: 'ticketStats',
                Header: 'Tickets',
                accessor: 'ticketStats',
            },
        ],
        []
    );

    const hiddenColumns = isMobile ? ['sponsor', 'ticketStats', 'status'] : [];

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data: shows || [],
                initialState: {
                    hiddenColumns,
                },
            },
            useSortBy
        );

    return (
        <Box>
            <Table {...getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>

                <Tbody {...getTableBodyProps()}>
                    {rows &&
                        rows.map((row) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        switch (cell.column.id) {
                                            case 'event.info':
                                                return (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                    >
                                                        <ShowInfo
                                                            show={row.original}
                                                        />
                                                    </Td>
                                                );

                                            case 'status':
                                                return (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                    >
                                                        <ShowStatus
                                                            status={
                                                                cell.row.values
                                                                    .status
                                                            }
                                                        />
                                                    </Td>
                                                );

                                            case 'ticketStats':
                                                return (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                    >
                                                        <ShowTicketStats
                                                            ticketStats={
                                                                cell.row.values
                                                                    ?.ticketStats
                                                            }
                                                        />
                                                    </Td>
                                                );
                                            default:
                                                return (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render('Cell')}
                                                    </Td>
                                                );
                                        }
                                    })}
                                </Tr>
                            );
                        })}
                </Tbody>
            </Table>
        </Box>
    );
};

const ShowFilters = (props: any) => {
    const { query } = props;
    const [status, setStatus] = useState(query?.status || 'all');
    const [date, setDate] = useState(query?.date || 'next');
    const [featured, setFeatured] = useState(query?.featured || 'all');

    const { onSearch } = props;

    const handleSearch = () => {
        onSearch({ status, date, featured });
    };

    return (
        <Stack
            direction={{ base: 'column', sm: 'row' }}
            bgColor="gray.100"
            py={2}
            px={4}
            borderRadius="6px"
            spacing={4}
        >
            <Flex direction="column">
                <Text pb={2}>By status</Text>
                <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <option value="all">All shows</option>
                    <option value={SHOW.STATUS.PUBLISHED}>Published</option>
                    <option value={SHOW.STATUS.DRAFT}>Draft</option>
                    <option value={SHOW.STATUS.REMOVED}>Removed</option>
                </Select>
            </Flex>
            <Flex direction="column">
                <Text pb={2}>By date</Text>
                <Select value={date} onChange={(e) => setDate(e.target.value)}>
                    <option value="all">All shows</option>
                    <option value="next">Next shows</option>
                    <option value="past">Past shows</option>
                </Select>
            </Flex>
            <Flex direction="column">
                <Text pb={2}>Featured</Text>
                <Select
                    defaultValue={featured}
                    onChange={(e) => setFeatured(e.target.value)}
                >
                    <option value="all">All shows</option>
                    <option value="yes">Only featured</option>
                </Select>
            </Flex>
            <Spacer />
            <Flex direction="row" align="center">
                <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={handleSearch}
                >
                    Search
                </Button>
            </Flex>
        </Stack>
    );
};

const ShowInfo = ({ show }: any) => {
    return (
        <Flex direction="column">
            <Flex direction="row" fontWeight="bold" pb={2}>
                <LinkRouter to={`/shows/${show.id}`}>
                    {show?.event?.name}
                </LinkRouter>

                {show?.featured && (
                    <Box ml={2}>
                        <FaStar />
                    </Box>
                )}
            </Flex>
            {show.status && (
                <Box display={{ base: 'block', sm: 'none' }} mb={2}>
                    <ShowStatus status={show.status} />
                </Box>
            )}
            <Box fontSize="sm">{show?.event?.date}</Box>
            <Box fontSize="sm">/{show?.id}</Box>
        </Flex>
    );
};
