import React from 'react';

import { Navigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { Text } from '@chakra-ui/react';

export function PrivateRoute({ children, ...rest }: any) {
    const { authenticated, loadingAuthState } = useAuth();

    if (loadingAuthState) return <Text>Loading...</Text>;

    return authenticated ? children : <Navigate to={'/'} />;
}
