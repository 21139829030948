import React, { useState } from 'react';

import { useQuery } from 'react-query';
import {
    getShowById,
    publishShowById,
    deleteShowById,
    draftShowById,
} from '../../libs/show-api';
import {
    Box,
    Button,
    Stack,
    Flex,
    useToast,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Badge,
    Tooltip,
} from '@chakra-ui/react';
import { Link as LinkRouter, useParams } from 'react-router-dom';
import { PageTitle } from '../layouts';
import { ShowTicketStats, ShowCard, ShowEmbedCode } from '../shows';
import { SHOW } from '../../utils/constants';
import {
    FaRegTrashAlt,
    FaReply,
    FaPencilAlt,
    FaCheckCircle,
    FaTachometerAlt,
    FaTicketAlt,
} from 'react-icons/fa';

export const ShowDetailsPage = () => {
    let { showId } = useParams();
    const toast = useToast();
    const [isLoadingPublish, setIsLoadingPublish] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isLoadingDraft, setIsLoadingDraft] = useState(false);

    const { data: show, refetch } = useQuery(['shows', showId], () =>
        getShowById('' + showId)
    );

    const handlePublishShow = () => {
        setIsLoadingPublish(true);
        publishShowById('' + showId)
            .then(() => {
                toast({
                    title: 'Show published',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setIsLoadingPublish(false);
                refetch();
            })
            .catch((err: any) => {
                console.error(err);
                toast({
                    title: 'Error publishing show',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                setIsLoadingPublish(false);
                refetch();
            });
    };

    const handleDeleteShow = () => {
        setIsLoadingDelete(true);
        deleteShowById('' + showId).then(() => {
            toast({
                title: 'Show deleted',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setIsLoadingDelete(false);
            refetch();
        });
    };

    const handleDraftShow = () => {
        setIsLoadingDraft(true);
        draftShowById('' + showId).then(() => {
            toast({
                title: 'Show moved to draft',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setIsLoadingDraft(false);
            refetch();
        });
    };

    return (
        <Box>
            {!show && <></>}

            {show && (
                <Box>
                    <PageTitle
                        title={show?.event?.name || '-'}
                        breadcrumb={[{ url: '/shows', label: 'Shows' }]}
                    >
                        <ShowButtons
                            show={show}
                            isLoadingPublish={isLoadingPublish}
                            isLoadingDelete={isLoadingDelete}
                            isLoadingDraft={isLoadingDraft}
                            onPublishShow={handlePublishShow}
                            onDraftShow={handleDraftShow}
                            onDeleteShow={handleDeleteShow}
                        />
                    </PageTitle>

                    <Flex direction={{ base: 'column', sm: 'row' }}>
                        <Box
                            width={{ base: '100%', sm: '70%' }}
                            ml={{ base: 0, sm: 4 }}
                        >
                            <ShowCard show={show} />
                        </Box>
                        <Stack
                            width={{ base: '100%', sm: '30%' }}
                            mb={{ base: 6, sm: 0 }}
                            spacing={4}
                        >
                            <ShowTicketStats ticketStats={show.ticketStats} />

                            {show && <ShowEmbedCode show={show} />}
                        </Stack>
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

export const ShowButtons = (props: any) => {
    const { show, onPublishShow, onDraftShow, onDeleteShow, isLoadingPublish } =
        props;
    return (
        <Stack direction="row" align="right" spacing={2}>
            {[SHOW.STATUS.PUBLISHED, SHOW.STATUS.DRAFT].includes(
                show.status
            ) && (
                <Button
                    isLoading={isLoadingPublish}
                    leftIcon={<FaCheckCircle />}
                    onClick={onPublishShow}
                >
                    Publish
                    {show.status === SHOW.STATUS.PUBLISHED &&
                        show.unpublishedChangesCount > 0 && (
                            <Tooltip
                                label={
                                    'Cantidad de nuevos cambios sin publicar'
                                }
                            >
                                <Badge
                                    colorScheme="red"
                                    ml={2}
                                    borderRadius={4}
                                    alt="Some content"
                                >
                                    {show.unpublishedChangesCount}
                                </Badge>
                            </Tooltip>
                        )}
                </Button>
            )}

            {[SHOW.STATUS.PUBLISHED].includes(show.status) && (
                <LinkRouter to={`/shows/${show.slug}/control-panel`}>
                    <Button
                        display={{ base: 'none', md: 'flex' }}
                        variant="solid"
                        leftIcon={<FaTachometerAlt />}
                    >
                        Control Panel
                    </Button>
                    <Button
                        display={{ base: 'flex', md: 'none' }}
                        variant="solid"
                        leftIcon={<FaTachometerAlt />}
                    >
                        Control
                    </Button>
                </LinkRouter>
            )}

            {[SHOW.STATUS.PUBLISHED, SHOW.STATUS.DRAFT, undefined].includes(
                show.status
            ) && (
                <Box display={{ base: 'none', xl: 'flex' }}>
                    <LinkRouter to={`/shows/${show.slug}/tickets`}>
                        <Button variant="solid" leftIcon={<FaTicketAlt />}>
                            Tickets
                        </Button>
                    </LinkRouter>
                </Box>
            )}

            {[SHOW.STATUS.PUBLISHED, SHOW.STATUS.DRAFT].includes(
                show.status
            ) && (
                <Box display={{ base: 'none', md: 'flex' }}>
                    <LinkRouter to={`/shows/${show.slug}/edit`}>
                        <Button variant="solid" leftIcon={<FaPencilAlt />}>
                            Edit
                        </Button>
                    </LinkRouter>
                </Box>
            )}

            <Menu>
                <MenuButton as={Button}>...</MenuButton>
                <MenuList>
                    {[SHOW.STATUS.PUBLISHED, SHOW.STATUS.DRAFT].includes(
                        show.status
                    ) && (
                        <LinkRouter to={`/shows/${show.slug}/edit`}>
                            <MenuItem
                                icon={<FaPencilAlt />}
                                display={{ base: 'flex', md: 'none' }}
                            >
                                Edit
                            </MenuItem>
                        </LinkRouter>
                    )}

                    {[
                        SHOW.STATUS.PUBLISHED,
                        SHOW.STATUS.DRAFT,
                        undefined,
                    ].includes(show.status) && (
                        <LinkRouter to={`/shows/${show.slug}/tickets`}>
                            <MenuItem
                                icon={<FaTicketAlt />}
                                display={{ base: 'flex', xl: 'none' }}
                            >
                                Tickets
                            </MenuItem>
                        </LinkRouter>
                    )}

                    {[
                        SHOW.STATUS.PUBLISHED,
                        SHOW.STATUS.REMOVED,
                        undefined,
                    ].includes(show.status) && (
                        <MenuItem onClick={onDraftShow} icon={<FaReply />}>
                            Move to Draft
                        </MenuItem>
                    )}
                    {[SHOW.STATUS.PUBLISHED, SHOW.STATUS.DRAFT].includes(
                        show.status
                    ) && (
                        <>
                            <MenuDivider />
                            <MenuItem
                                onClick={onDeleteShow}
                                icon={<FaRegTrashAlt />}
                            >
                                Delete
                            </MenuItem>
                        </>
                    )}
                </MenuList>
            </Menu>
        </Stack>
    );
};
